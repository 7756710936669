<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step mx-auto">
        <EntrepreneurFileUpload
          class="w-100"
          @fileSubmitted="fileSubmitted"
          :tenant_id="$route.params.tenant_id"
        ></EntrepreneurFileUpload>
        <button
          class="btn btn-outline-primary btn-block mt-3 mb-5"
          @click="$router.push({ name: 'MyProfile' })"
        >
          {{ $t("Abort verification, add later") }}
        </button>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <RightPoints active="2" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import EntrepreneurFileUpload from "../../../components/User/EntrepreneurFileUpload.vue";
import RightPoints from "../Expat/RightPoints.vue";

export default {
  data() {
    return {};
  },
  components: {
    RightSection,
    AuthLayoutContent,
    EntrepreneurFileUpload,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["accounts"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async created() {
    this.step = +this.$route.params.step;
  },
  methods: {
    fileSubmitted() {
      if (this.tenant.digid_verified) {
        this.$router.push({
          name: "MyProfile",
        });
      } else {
        this.$router.push({
          name: "Digid",
          params: { tenant_id: this.$route.params.tenant_id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.input-file {
  display: none;
}

.fileName {
  max-width: 200px;
  word-break: break-all;
}
</style>
